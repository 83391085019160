import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useState } from 'react';

import 'styles/Home.module.css';

import { HOME_PAGE, TESTIMONIALS } from 'app/helpers/apiEndpoint';
import REQUEST from 'app/helpers/http.service';

import '../pages/home.css';

const HomeHeroSection = dynamic(
  () => import('app/components/common/HomeHeroSection')
);
const BusinessesNumber = dynamic(
  () => import('app/components/common/BusinessesNumber')
);
const Partner = dynamic(() => import('app/components/common/Partner'));
const WhyVlink = dynamic(() => import('app/components/common/WhyVlink'));
const CaseSection = dynamic(() => import('app/components/common/CaseSection'));
const ReadyToScale = dynamic(
  () => import('app/components/common/ReadyToScale')
);
const CaseStudy = dynamic(() => import('app/components/common/CaseStudy'));
const OurPartners = dynamic(() => import('app/components/common/OurPartners'));
const TrustedCompanies = dynamic(
  () => import('app/components/common/TrustedCompanies')
);
const OurClient = dynamic(() => import('app/components/common/OurClient'));
const TestimonialData = dynamic(
  () => import('app/components/warehouse/TestimonialData')
);
const Appointment = dynamic(() => import('app/components/common/Appointment'));
const DataResources = dynamic(
  () => import('app/components/warehouse/DataResources')
);
const HireScheduleTalent = dynamic(
  () => import('app/components/common/HireScheduleTalent')
);
const Faq = dynamic(() => import('app/components/Teams/Faq'));
const WriteGetInTouch = dynamic(
  () => import('app/components/writetous/WriteGetInTouch')
);
const Metatag = dynamic(() => import('app/components/metaTag'));

export default function Home({ res }) {
  const { asPath } = useRouter();

  const [modalScheduleCall, setModalScheduleCall] = useState(false);

  const router = useRouter();
  const canonicalUrl = (
    `${process.env.NEXT_PUBLIC_BASE_URL}` +
    (router.asPath === '/' ? '' : router.asPath)
  ).split('?')[0];

  const { homeData, testimonials } = JSON.parse(res);

  if (homeData?.error) {
    return <div>{homeData?.error}</div>;
  }

  return (
    <div className="md:pt-[80px] pt-[70px]">
      <Head>
        <title>
          {homeData?.Seo?.metaTitle ? homeData?.Seo?.metaTitle : `VLink`}
        </title>
        <meta
          name="description"
          content={homeData?.Seo?.metaDescription || 'Vlink Description'}
        />

        <meta
          property="og:title"
          content={homeData?.Seo?.metaTitle || homeData?.title || `Vlink`}
        />
        <meta
          property="og:description"
          content={
            homeData?.Seo?.metaDescription ||
            homeData?.description ||
            'Vlink Description'
          }
        />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_BASE_URL}${asPath}`}
        />

        <Metatag
          content={
            homeData?.Seo?.metaImage?.data?.attributes?.url ||
            homeData?.HeroSection?.image?.data?.attributes?.url
          }
          pageUrl={canonicalUrl}
        />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      {homeData?.HeroSection && (
        <HomeHeroSection
          data={homeData?.HeroSection}
          ishome={true}
          setModalCall={setModalScheduleCall}
        />
      )}
      {homeData?.DigitalTrans && (
        <div className="xl:my-[75px] my-[35px]">
          <BusinessesNumber digital={homeData?.DigitalTrans} />
        </div>
      )}
      {homeData?.Services && (
        <Partner
          setModalCall={setModalScheduleCall}
          isPartnerFrontend={true}
          partner={homeData?.Services}
          istopbotompaddremoveinside={true}
          isIconBgClr={true}
          isHomeTxtClr={true}
        />
      )}
      {homeData?.WhoWeAre && (
        <WhyVlink
          textimage={homeData?.WhoWeAre}
          setModalCall={setModalScheduleCall}
          isHomeFont={true}
          isFontSize={'55px'}
        />
      )}

      {homeData?.Unlocking && <CaseSection ourwork={homeData?.Unlocking} />}
      {homeData?.ReadyToScale && (
        <div className="xl:my-[75px] my-[45px]">
          <ReadyToScale
            ready={homeData?.ReadyToScale}
            setModalCall={setModalScheduleCall}
          />
        </div>
      )}
      {homeData?.RealWorld && <CaseStudy realworld={homeData?.RealWorld} />}
      {homeData?.Clients && <OurPartners Clients={homeData?.Clients} />}
      {homeData?.Trusted500 && (
        <div className="xl:my-[75px] my-[45px]">
          <TrustedCompanies Trusted500={homeData?.Trusted500} />
        </div>
      )}
      {homeData?.ClientSucess && (
        <OurClient ClientSucess={homeData?.ClientSucess} isColor={'#00060F'} />
      )}
      <TestimonialData testimonials={testimonials} isNewTestimonial={true} />
      {homeData?.ReadyTo && (
        <Appointment
          appointment={homeData?.ReadyTo}
          setModalCall={setModalScheduleCall}
        />
      )}
      {homeData?.MoreCaseStudy && (
        <div className="xl:mt-[75px] mt-[45px]">
          <DataResources
            resources={homeData?.MoreCaseStudy}
            ishome={true}
            ishomeFont={true}
          />
        </div>
      )}
      {homeData?.Faqs && (
        <Faq
          section={homeData?.Faqs}
          isFaq={true}
          forCSS={true}
          isdevops={true}
          ishome={true}
        />
      )}
      <WriteGetInTouch />
      {modalScheduleCall && (
        <HireScheduleTalent
          ButtonText={modalScheduleCall}
          setIsOpen={setModalScheduleCall}
        />
      )}
    </div>
  );
}

export async function getStaticProps() {
  const [homeData, testimonials] = await Promise.all([
    REQUEST({
      method: 'GET',
      url: HOME_PAGE,
    }),
    REQUEST({
      method: 'GET',
      url: TESTIMONIALS,
    }),
  ]);
  const res = JSON.stringify({
    homeData: homeData?.data?.data?.attributes,
    testimonials: testimonials?.data?.data?.attributes,
  });
  return {
    props: { res },
  };
}
